<template>
    <div class='container'>
        <HeaderNav ref="headerNav" :keepSearchShow="true" />
        <div class="content">
            <div class="banner" v-if="false">
                <!-- 顶部搜索区域 -->
                <div class="searchBlock">
                    <div class="columnTip">
                        <div class="columns" v-for="item in columns" :key="item.id" @click=setColumn(item)>
                            <div :class="{active:searchOpt.searchColumn&&item.id==searchOpt.searchColumn.id}">
                                {{item.name}}</div>
                            <img v-if="searchOpt.searchColumn&&item.id==searchOpt.searchColumn.id"
                                src="@/assets/img/triangle_down_topic.png" alt="">
                        </div>
                    </div>
                    <div class="searchBox">
                        <input type="text" placeholder="搜索名称 / 关键词" v-model="searchOpt.keyword" @keyup.enter="loadData"
                            maxlength="50" />
                        <div class="searchBtn" @click="loadData">
                            <img src="@/assets/img/search_icon_white.png" alt="" class="searchIcon">
                        </div>
                    </div>
                    <div class="quickTip">
                        <span>热门搜索:</span>
                        <span class="hotKey" v-for="item in hotKeys" :key="item.id"
                            @click="setHotKey(item.name)">{{item.name}}</span>
                    </div>
                </div>
                <div class="filterBlock">

                    <div class="row">
                        <div class="rowLabel">
                            <span>搜索类型：</span>
                            <div class="bor-right"></div>
                        </div>
                        <div class="rowVal">
                            <div :class="{
                                'selectAll-un':searchOpt.searchType!=0,
                                'selectAll-en':searchOpt.searchType==0,
                                }" @click="setSearchType(0)">
                                全部
                            </div>
                            <div class="filtersBox">
                                <div :class="{filters:true,'filters-active':searchOpt.searchType==item.val}"
                                    v-for="(item) in searchTypes" :key="item.val" @click="setSearchType(item.val)">
                                    {{item.label}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="rowLabel"><span>所在地区：</span>
                            <div class="bor-right"></div>
                        </div>
                        <div class="rowVal">
                            <div class="filters-area" v-for="item in areas" :key="item.value">
                                <input type="checkbox" v-model="item.selectFlag"
                                    @change="setSearchArea(item)" /><span>{{item.subLabel}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="rowLabel"><span>行业分类：</span>
                            <div class="bor-right"></div>
                        </div>
                        <div class="rowVal">
                            <div :class="{'selectAll-en':!searchOpt.searchIndustry,'selectAll-un':searchOpt.searchIndustry}"
                                @click="setSearchIndustry(undefined)">全部</div>
                            <div class="filtersBox">
                                <div :class="{filters:true,'filters-active':searchOpt.searchIndustry&&item.id==searchOpt.searchIndustry.id}"
                                    v-for="(item) in industries" :key="item.id" @click="setSearchIndustry(item)">
                                    {{item.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="rowLabel"><span>栏目：</span>
                            <div class="bor-right"></div>
                        </div>
                        <div class="rowVal">
                            <div :class="{'selectAll-en':!searchOpt.searchColumn,'selectAll-un':searchOpt.searchColumn}"
                                @click="setSearchColumn(undefined)">全部</div>
                            <div class="filtersBox">
                                <div :class="{filters:true,'filters-active':searchOpt.searchColumn&&item.id==searchOpt.searchColumn.id}"
                                    v-for="(item) in columns" :key="item.id" @click="setSearchColumn(item)">
                                    {{item.name}}</div>
                            </div>
                        </div>

                    </div>
                    <div class="row" style="min-height:26px">
                        <div class="rowLabel"><span>发布时间：</span>
                            <div class="bor-right"></div>
                        </div>
                        <div class="rowVal">
                            <div :class="{'selectAll-en':!searchOpt.searchTime,'selectAll-un':searchOpt.searchTime}"
                                @click="setSearchTime(undefined)">全部
                            </div>
                            <div class="filtersBox">
                                <div :class="{filters:true,'filters-active':item.selectFlag}"
                                    v-for="(item) in searchTimes" :key="item.label" @click="setSearchTime(item)">
                                    {{item.label}}
                                </div>
                                <div class="diyTime" v-if="searchOpt.searchTime&&searchOpt.searchTime.days==undefined">
                                    <el-date-picker v-model="diySearchObj.createDate" class="timePicker" type="date"
                                        placeholder="选择日期" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="timeMargin"></div>
                                    <el-date-picker v-model="diySearchObj.endDate" class="timePicker" type="date"
                                        placeholder="选择日期" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="searchFilter yellowBtn" @click="loadData">
                                        <img src="@/assets/img/search_icon_white.png" alt="">
                                        <span>搜索</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="rowLabel">
                            <span>已选条件：</span>
                            <div class="bor-right"></div>
                        </div>
                        <div class="rowVal">
                            <!-- 已选条件包括地区、行业分类、栏目、发布时间 -->
                            <!-- 已选地区 -->
                            <div class="selectedFilters" v-for="item in searchOpt.selectedArr.selectedAreas"
                                :key="item.val" @click="deleteSelected(0,item)">
                                <span>{{item.label}}</span>
                                <img src="@/assets/img/selected_delete.png" alt="">
                            </div>
                            <!-- 已选行业 -->
                            <div class="selectedFilters" v-for="item in searchOpt.selectedArr.selectedIndustries"
                                :key="item.val">
                                <span>{{item.label}}</span>
                                <img src="@/assets/img/selected_delete.png" alt="" @click="deleteSelected(1)">
                            </div>
                            <!-- 已选栏目 -->
                            <div class="selectedFilters" v-for="item in searchOpt.selectedArr.selectedColumns"
                                :key="item.val">
                                <span>{{item.label}}</span>
                                <img src="@/assets/img/selected_delete.png" alt="" @click="deleteSelected(2)">
                            </div>
                            <!-- 已选时间 -->
                            <div class="selectedFilters" v-for="item in searchOpt.selectedArr.selectedTimes"
                                :key="item.val">
                                <span>{{item.label}}</span>
                                <img src="@/assets/img/selected_delete.png" alt="" @click="deleteSelected(3)">
                            </div>
                            <div class="clearFilter" @click="resetSelected">清除选项</div>
                            <div class="searchFilter yellowBtn" @click="loadData">
                                <img src="@/assets/img/search_icon_white.png" alt="">
                                <span>搜索</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inner">
                <div class="pushProjects">
                    <div class="list">
                        <img src="@/assets/img/pc_searchNull.png" alt="" class="searchNull"
                            v-if="total==0&&records.length==0">
                        <!-- <img src="@/assets/img/pc_searching.png" alt="" class="searching"
                            v-else-if="total==1&&records.length==0"> -->
                        <div class="row" v-for="item in records" :key="item.uuid" @click="goTopicDetail(item)">
                            <div class="topicInfo">
                                <div class="tips">
                                    <div class="tip areaTip">{{item.regionName.substring(0,3)}}</div>
                                    <div class="tip columnTip">{{item.managementName}}</div>
                                    <div class="tip industryTip"
                                        v-for="(item,index) in (item.classificationName ? item.classificationName.split(',').slice(0,3) : [])"
                                        :key="index + item">{{item}}</div>
                                    <div class="tip industryTip"
                                        v-if="item.classificationName&&item.classificationName.split(',').length > 3">
                                        更多>></div>
                                </div>
                                <div class="topicTit" :style="item.browsingType==1?'color:#678dc8':''"
                                    v-html="item.title">
                                </div>
                            </div>
                            <div class="topicTime">{{item.createDate}}</div>
                        </div>
                    </div>
                </div>
                <div class="pageNintionBox">
                    <!-- :page-size="pageSize" :page-count="5" :total="total" @current-change="loadData(true)" -->
                    <el-pagination background layout="prev, pager, next" :current-page.sync="currentPage"
                        :page-size="pageSize" :page-count="5" :total="total" @current-change="handleSizeChange"
                        hide-on-single-page :class="{'myPager':true,'hideLastPage':hideLastPage}">
                        <!-- , slot,jumper -->
                        <!-- <div class="pageNumTip">
                            <span class="pageNumNow">{{currentPage}}</span>
                            <span>/</span>
                            <span>{{maxPage}}</span>
                        </div> -->
                    </el-pagination>
                    <!-- <div class="pageNumConfirm" v-if="total>0&&maxPage>1">确定</div> -->
                </div>
            </div>
        </div>
        <FooterNav />
        <SlideFixNav />
    </div>
</template>

<script>
    import HeaderNav from "@/components/HeaderNav"
    import FooterNav from "@/components/FooterNav"
    import SlideFixNav from "@/components/SlideFixNav"
    import SIdentify from "@/components/SIdentify"
    import {
        getHomepage,
    } from '@/request/homepage';
    import {
        getHotKey,
        searchTopic
    } from "@/request/search";
    import {
        viewCount
    } from "@/request/viewCount"
    import {
        columnManagement
    } from "@/request/column"
    import {
        getIndustry
    } from "@/request/industry"
    import {
        getTopic,
        getTopicVip,
        collectTopic,
        channelSave
    } from "@/request/topic"

    import areas from "@/utils/area"
    export default {
        name: 'Search',
        data() {
            return {
                //搜索
                columns: [], //栏目
                areas: [], //地区
                industries: [], //行业
                hotKeys: [{}, {}, {}, {}, {}], //热搜关键字
                //搜索条件配置
                searchOpt: {
                    keyword: "", //搜索关键字
                    selectedArr: { //已选条件
                        selectedAreas: [{ //已选地区
                            label: "全国",
                            val: "全国"
                        }],
                        selectedIndustries: [{ //已选行业
                            label: "全部行业",
                            val: "全部行业"
                        }],
                        selectedColumns: [ //已选栏目
                            {
                                label: "全部栏目",
                                val: "全部栏目"
                            }
                        ],
                        selectedTimes: [ //已选时间
                            {
                                label: "全部时间",
                                val: "全部时间"
                            }
                        ],
                    },
                    searchType: "0", //搜索类型
                    searchIndustry: undefined, //搜索行业，默认全部
                    searchColumn: undefined, //搜索栏目，默认全部
                    searchTime: undefined, //搜索时间，默认全部
                }, //搜索条件对象
                searchTypes: [{ //搜索类型
                        label: "标题",
                        val: "1",
                        selectFlag: false,
                    },
                    {
                        label: "内容",
                        val: "2",
                        selectFlag: false,
                    }
                ],
                searchTimes: [ //搜索时间
                    {
                        label: "最近一个月",
                        val: "",
                        selectFlag: false,
                        days: 30,
                    },
                    {
                        label: "最近三个月",
                        val: "",
                        selectFlag: false,
                        days: 90,
                    },
                    {
                        label: "最近一年",
                        val: "",
                        selectFlag: false,
                        days: 365,
                    },
                    {
                        label: "最近三年",
                        val: "",
                        selectFlag: false,
                        days: 3 * 365,
                    },
                    {
                        label: "自定义时间",
                        val: "",
                        selectFlag: false,
                        days: undefined,
                    }
                ],
                diySearchObj: {
                    createDate: "",
                    endDate: ""
                },
                //搜索分页配置
                currentPage: 0,
                maxPage: 1,
                records: [],
                pageSize: 30,
                total: 1,
                hideLastPage: false
            };
        },
        created() {
            if (this.$route.query.keyword) {
                this.$set(this.searchOpt, "keyword", this.$route.query.keyword)
            }
            this.currentPage = Number(this.$route.query.pageNum) || 1
        },
        mounted() {
            this.getQuery()
            this.initData();
            this.loadData();
        },
        beforeDestroy() {},
        watch: {
            userInfo(val) {
                if (val) {
                    this.loadData()
                }
            },
            "$route.query"(val) {
                this.getQuery()
                // this.currentPage = 0;
                this.currentPage = Number(this.$route.query.pageNum) || 1
                this.loadData()
            },
            "searchOpt.keyword"(val) {
                this.$refs.headerNav.keyword = val;
            }
        },
        methods: {
            //router传参
            getQuery() {
                let query = this.$route.query;
                if (query.keyword) {
                    this.$set(this.searchOpt, "keyword", query.keyword)
                }
                if (query.managementId && query.managementId != 1) {
                    this.$set(this.searchOpt, "searchColumn", {
                        id: query.managementId,
                        name: query.managementName
                    })
                    this.$set(this.searchOpt["selectedArr"], "selectedColumns", [{
                        label: query.managementName,
                        val: query.name + query.managementId
                    }])
                }
                if (query.classificationId && query.classificationId != 1) {
                    this.$set(this.searchOpt, "searchIndustry", {
                        id: query.classificationId,
                        name: query.classificationName
                    })
                    this.$set(this.searchOpt["selectedArr"], "selectedIndustries", [{
                        label: query.classificationName,
                        val: query.name + query.classificationId
                    }])
                }
            },
            /** 数据初始化 */
            initData() {
                this.initAreas()
                this.initColumns()
                this.initHotKey()
                this.channelCount()
                this.initIndustry()
            },
            //地区
            initAreas() {
                this.areas = areas.map(item => {
                    let subIndex = item.label.indexOf("市") > -1 ? item.label.indexOf("市") : item.label.indexOf(
                        "省");
                    item.subLabel = subIndex > -1 ? item.label.substring(0, subIndex) : item.label.substring(0,
                        2)
                    item.selectFlag = false;
                    item.val = item.label;
                    return item
                })
                this.areas.unshift({
                    label: "全国",
                    subLabel: "全国",
                    val: "",
                    selectFlag: true
                })
            },
            //栏目
            initColumns() {
                columnManagement({}).then(res => {
                    //调用前端排序方法进行排序
                    // res.data = this.sortByCoum(res.data, 'sort');
                    this.columns = res.data.filter(item => {
                        return item.name != "全部"
                    }).map(column => {
                        column.selectFlag = false;
                        return column;
                    });
                })
            },
            //排序
            sortByCoum(array, key) {
                return array.sort(function (objectN, objectM) {
                    var valueN = objectN[key]
                    var valueM = objectM[key]
                    if (valueN > valueM) return 1
                    else if (valueN < valueM) return -1
                    else return 0
                });
            },
            //行业
            initIndustry() {
                getIndustry({}).then(res => {
                    this.industries = res.data.filter(item => {
                        return item.name != "全国"
                    }).map(ind => {
                        ind.selectFlag = false;
                        return ind
                    })
                })
            },
            //热搜
            initHotKey() {
                getHotKey({}).then(res => {
                    this.hotKeys = res.data;
                })
            },
            //渠道统计/访问计数
            channelCount() {
                //访问计数
                viewCount();
                //邮件推送
                if (this.$route.query.from == "email") {
                    channelSave(0).then(res => {})
                } else {
                    channelSave(2).then(res => {})
                }

            },

            /** 搜索框 */
            //搜索选项的处理
            searchOpt_fix() {

                let obj = {
                    //关键字
                    keyword: this.searchOpt.keyword,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                }
                if (obj.keyword) {
                    obj.searchType = this.searchOpt.searchType
                }
                if (this.searchOpt.searchColumn) { //栏目

                    obj.managementId = this.searchOpt.searchColumn.id;
                    obj.managementName = this.searchOpt.searchColumn.name;
                }
                if (this.searchOpt.searchIndustry) { //行业

                    obj.classificationId = this.searchOpt.searchIndustry.id;
                    obj.classificationName = this.searchOpt.searchIndustry.name;
                }
                if (this.searchOpt.selectedArr.selectedAreas.length > 0) { //地区
                    if (this.searchOpt.selectedArr.selectedAreas[0].label != "全国") {
                        obj.regionName = this.searchOpt.selectedArr.selectedAreas.map(item => {
                            return item.val
                        }).join(",");
                    }
                }
                if (this.searchOpt.selectedArr.selectedTimes.length > 0) {
                    if (this.searchOpt.selectedArr.selectedTimes[0].label != "全部时间") {
                        if (this.searchOpt.searchTime.days) {
                            obj.createDate = this.getDateFor(this.searchOpt.searchTime.days + 1);
                            obj.endDate = this.getDateFor(-1);
                        } else {
                            obj.createDate = this.diySearchObj.createDate;
                            obj.endDate = this.getNextDay(this.diySearchObj.endDate);
                        }
                    }

                }
                return obj
            },
            handleSizeChange(val) {
                this.$router.push({
                    path: '/columnSearch',
                    query: {
                        ...this.$route.query,
                        pageNum: val
                    }
                })

            },
            //搜索
            loadData(type) {
                let _data = this.searchOpt_fix()
                // if (!type) {
                //     _data.currentPage = 1;
                // }
                searchTopic(_data).then(res => {
                    this.maxPage = res.data.pages;
                    this.records = this.fixData(res.data.records);
                    this.total = parseInt(res.data.total);
                    if (this.maxPage < 40) {
                        this.hideLastPage = false;
                    } else {
                        this.hideLastPage = true;
                    }
                })
            },
            //修剪数据
            fixData(data) {
                let resultArr = data.map(item => {
                    item.createDate = item.createDate ? item.createDate.substring(0, 10) : "";
                    return item
                })
                return resultArr
            },
            //搜索框选择栏目
            setColumn(item) {
                this.$set(this.searchOpt, "searchColumn", item)
                this.$set(this.searchOpt["selectedArr"], "selectedColumns", [{
                    label: item.name,
                    val: item.name + item.id
                }])
            },
            //设置热搜关键词
            setHotKey(key) {
                this.$set(this.searchOpt, "keyword", key);
                this.loadData()
            },
            //设置搜索类型
            setSearchType(type) {
                this.$set(this.searchOpt, "searchType", type)
            },
            //设置搜索地区
            setSearchArea(region) {
                let allAreas = true;
                if (region.label == "全国") {
                    if (region.selectFlag) { //勾选全国
                        allAreas = true;
                        this.areas = this.areas.map(item => {
                            item.selectFlag = true;
                            return item
                        })
                    } else { //取消勾选全国
                        allAreas = false;
                        this.areas = this.areas.map(item => {
                            item.selectFlag = false;
                            return item
                        })
                    }
                } else {
                    if (region.selectFlag) { //勾选操作
                        this.areas.forEach(item => {
                            if (item.label != "全国" && !item.selectFlag) {
                                allAreas = false
                            }
                        })
                        if (allAreas) {
                            this.areas = this.areas.map(item => {
                                item.selectFlag = true;
                                return item
                            })
                        }
                    } else { //取消勾选操作
                        allAreas = false;
                        this.$set(this.areas, 0, {
                            label: "全国",
                            subLabel: "全国",
                            val: "",
                            selectFlag: false
                        })
                    }
                }
                if (allAreas) {
                    this.$set(this.searchOpt["selectedArr"], "selectedAreas", [{
                        label: "全国",
                        val: "全国"
                    }])
                } else {
                    let arr = this.areas.filter(item => {
                        return item.subLabel != "全国" && item.selectFlag
                    }).map(area => {
                        return {
                            label: area.subLabel,
                            val: area.label
                        }
                    })
                    this.$set(this.areas, 0, {
                        label: "全国",
                        subLabel: "全国",
                        val: "",
                        selectFlag: false
                    })
                    this.$set(this.searchOpt["selectedArr"], "selectedAreas", arr)
                }
            },
            //设置搜索行业
            setSearchIndustry(item) {
                if (item) {
                    this.$set(this.searchOpt, "searchIndustry", item);
                    this.$set(this.searchOpt["selectedArr"], "selectedIndustries", [{
                        label: item.name,
                        val: item.name + item.id
                    }])
                    //清空其他选择的行业分类
                    this.industries = this.industries.map(ind => {
                        if (ind.id != item.id) {
                            ind.selectFlag = false;
                        } else {
                            ind.selectFlag = true;
                        }
                        return ind
                    })
                } else { //选择全部
                    this.$set(this.searchOpt, "searchIndustry", undefined)
                    this.$set(this.searchOpt["selectedArr"], "selectedIndustries", [{
                        label: "全部行业",
                        val: "全部行业"
                    }])
                    //清空其他选择的行业分类
                    this.industries = this.industries.map(ind => {
                        ind.selectFlag = false;
                        return ind
                    })
                }
            },
            //设置搜索栏目
            setSearchColumn(item) {
                if (item) {
                    this.$set(this.searchOpt, "searchColumn", item);
                    this.$set(this.searchOpt["selectedArr"], "selectedColumns", [{
                        label: item.name,
                        val: item.name + item.id
                    }])
                    //清空其他选择的行业分类
                    this.columns = this.columns.map(ind => {
                        if (ind.id != item.id) {
                            ind.selectFlag = false;
                        } else {
                            ind.selectFlag = true;
                        }
                        return ind
                    })
                } else { //选择全部
                    this.$set(this.searchOpt, "searchColumn", undefined)
                    this.$set(this.searchOpt["selectedArr"], "selectedColumns", [{
                        label: "全部栏目",
                        val: "全部栏目"
                    }])
                    //清空其他选择的栏目分类
                    this.columns = this.columns.map(ind => {
                        ind.selectFlag = false;
                        return ind
                    })
                }
            },
            //设置搜索时间
            setSearchTime(item) {
                if (item) {
                    this.$set(this.searchOpt, "searchTime", item);
                    this.$set(this.searchOpt["selectedArr"], "selectedTimes", [{
                        label: item.label,
                        val: item.days
                    }])
                    //清空其他选择的行业分类
                    this.searchTimes = this.searchTimes.map(ind => {
                        if (ind.days != item.days) {
                            ind.selectFlag = false;
                        } else {
                            ind.selectFlag = true;
                        }
                        return ind
                    })
                } else { //选择全部
                    this.$set(this.searchOpt, "searchTime", undefined)
                    this.$set(this.searchOpt["selectedArr"], "selectedTimes", [{
                        label: "全部时间",
                        val: "全部时间"
                    }])
                    //清空其他选择的时间
                    this.searchTimes = this.searchTimes.map(ind => {
                        ind.selectFlag = false;
                        return ind
                    })
                }
            },
            //删除搜索条件
            deleteSelected(type, item) {
                //清除已选条件
                switch (type) {
                    case 0:
                        //清除地区选择
                        if (item.label != "全国") {
                            let arr = this.searchOpt.selectedArr.selectedAreas;
                            arr = arr.filter(area => {
                                return area.label != item.label
                            })
                            this.$set(this.searchOpt["selectedArr"], "selectedAreas", arr)
                        }
                        this.areas = this.areas.map(area => {
                            if (area.label == item.val) {
                                area.selectFlag = false;
                            }
                            return area
                        })
                        break;
                    case 1:
                        //清除行业选择
                        this.$set(this.searchOpt["selectedArr"], "selectedIndustries", [{ //已选行业
                            label: "全部行业",
                            val: "全部行业"
                        }])
                        this.industries = this.industries.map(ind => {
                            ind.selectFlag = false;
                            return ind
                        })
                        this.$set(this.searchOpt, "searchIndustry", undefined)
                        break;
                    case 2:
                        //清除栏目选择
                        this.$set(this.searchOpt["selectedArr"], "selectedColumns", [ //已选栏目
                            {
                                label: "全部栏目",
                                val: "全部栏目"
                            }
                        ])
                        this.columns = this.columns.map(ind => {
                            ind.selectFlag = false;
                            return ind
                        })
                        this.$set(this.searchOpt, "searchColumn", undefined)
                        break;
                    case 3:
                        //清除时间选择
                        this.$set(this.searchOpt["selectedArr"], "selectedTimes", [ //已选时间
                            {
                                label: "全部时间",
                                val: "全部时间"
                            }
                        ])
                        this.searchTimes = this.searchTimes.map(ind => {
                            ind.selectFlag = false;
                            return ind
                        })
                        this.$set(this.searchOpt, "searchTime", undefined)
                        break;
                }
            },
            //重置搜索条件
            resetSelected() {
                //清除已选条件
                this.$set(this.searchOpt, "selectedArr", {
                    selectedAreas: [{ //已选地区
                        label: "全国",
                        val: "全国"
                    }],
                    selectedIndustries: [{ //已选行业
                        label: "全部行业",
                        val: "全部行业"
                    }],
                    selectedColumns: [ //已选栏目
                        {
                            label: "全部栏目",
                            val: "全部栏目"
                        }
                    ],
                    selectedTimes: [ //已选时间
                        {
                            label: "全部时间",
                            val: "全部时间"
                        }
                    ],
                })
                //清除热门搜索
                this.$set(this.searchOpt, "searchType", 0)
                //清除地区选择
                this.areas = this.areas.map(item => {
                    item.selectFlag = true;
                    return item
                })
                //清除行业选择
                this.industries = this.industries.map(ind => {
                    ind.selectFlag = false;
                    return ind
                })
                this.$set(this.searchOpt, "searchIndustry", undefined)
                //清除栏目选择
                this.columns = this.columns.map(ind => {
                    ind.selectFlag = false;
                    return ind
                })
                this.$set(this.searchOpt, "searchColumn", undefined)
                //清除时间选择
                this.searchTimes = this.searchTimes.map(ind => {
                    ind.selectFlag = false;
                    return ind
                })
                this.$set(this.searchOpt, "searchTime", undefined)
            },




            //后台日期搜索是包前不包后，后台不好改，前端加一天
            //获取指定日期的下一天
            getNextDay(d) {
                d = new Date(d);
                d = +d + 1000 * 60 * 60 * 24;
                d = new Date(d);

                var yy = d.getFullYear();
                var mm = d.getMonth() + 1;
                var dd = d.getDate();
                var nextday = yy + "-";
                if (mm < 10) {
                    nextday += "0"
                }
                nextday += mm + "-";
                if (dd < 10) {
                    nextday += "0"
                }
                nextday += dd;
                return nextday;
            },
            //获取倒数day天的日期(day)
            getDateFor(day) {
                let today = new Date();
                let beforMilliseconds = today.getTime() - 1000 * 3600 * 24 * day;
                let beforday = new Date();
                beforday.setTime(beforMilliseconds);
                let strYear = beforday.getFullYear();
                let strDay = beforday.getDate();
                let strMonth = beforday.getMonth() + 1;
                if (strMonth < 10) {
                    strMonth = "0" + strMonth;
                }
                if (strDay < 10) {
                    strDay = "0" + strDay;
                }
                return strYear + "-" + strMonth + "-" + strDay;
            },
            /** 页面跳转 */
            //详情
            goTopicDetail(item) {
                item.browsingType = 1;
                let routeUrl = this.$router.resolve({
                    name: "Topic",
                    query: {
                        id: item.uuid
                    }
                })
                window.open(routeUrl.href, '_blank');

            }

        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo
            }
        },
        components: {
            HeaderNav,
            FooterNav,
            SIdentify,
            SlideFixNav
        },
    };
</script>
<style scoped lang="scss">
    .inner {
        width: 1200px;
        margin: 0 auto;
    }

    .searchFilter {
        width: 76px;
        height: 26px;
        background: #4385f5;
        border-radius: 13px;
        line-height: 26px;
        text-align: center;
        color: white;
        margin-right: 8px;
        margin-bottom: 8px;
        cursor: pointer;

    }

    .yellowBtn {
        width: 90px;
        background-color: #FF9200;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 18px;
            height: 16px;
            margin-right: 4px;
        }
    }

    .content {
        width: 100%;
        background-image: url("../assets/img/indexBanner.png");
        background-repeat: no-repeat;
        background-size: 100%;
        padding-top: 23px;

        .banner {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            padding: 40px 38px;
            box-sizing: border-box;
            z-index: 0;
            border-radius: 16px;
            box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
            background-color: #fff;
            margin-bottom: 40px;

            .searchBlock {
                width: 902px;
                margin: 0 auto;
                margin-bottom: 36px;
                font-size: 14px;

                .columnTip {
                    padding-left: 25px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    padding-bottom: 9px;
                    display: none;


                    .columns {
                        text-align: center;
                        margin-right: 16px;
                        color: #333;
                        cursor: pointer;

                        div {
                            border-radius: 16px;
                            padding: 6px 8px;
                            padding-bottom: 12px;
                        }

                        .active {
                            background-color: #4385f5;
                            padding-bottom: 6px;
                            color: white;
                        }

                        img {
                            width: 10px;
                            height: 6px;
                            display: block;
                            margin: 0 auto;
                        }
                    }

                }

                .searchBox {
                    height: 55px;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 28px;
                    background: rgba(255, 255, 255, 0.8);
                    margin-bottom: 10px;
                    border: 2px solid #4385f5;
                    box-sizing: border-box;


                    input {
                        font-size: 16px;
                        width: 100%;
                        padding-left: 35px;
                        box-sizing: border-box;
                        background: transparent;
                        color: #333333;
                        height: 55px;
                        line-height: 55px;
                        display: block;
                    }

                    .searchBtn {
                        width: 78px;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #4385f5;
                        border-top-right-radius: 28px;
                        border-bottom-right-radius: 28px;
                        cursor: pointer;

                        .searchIcon {
                            width: 23px;
                            height: 23px;
                        }
                    }

                }

                .quickTip {
                    padding-left: 25px;
                    font-weight: 400;
                    color: #a09c99;
                    line-height: 20px;

                    span {
                        margin-right: 12px;
                    }

                    .hotKey {
                        cursor: pointer;
                    }

                    .hotKey:hover {
                        text-decoration: underline;
                    }
                }
            }

            .filterBlock {
                font-size: 16px;

                .row {
                    margin-bottom: 26px;
                    display: flex;
                    align-items: flex-start;

                    .rowLabel {
                        width: 90px;
                        color: #666666;
                        padding-right: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-right: 20px;

                        .bor-right {
                            width: 2px;
                            height: 13px;
                            background-color: #f1efef;
                        }
                    }

                    .rowVal {
                        width: 1010px;
                        display: flex;
                        align-items: flex-start;
                        flex-wrap: wrap;

                        //已选条件
                        .selectedFilters {
                            border: 1px solid #4385f5;
                            border-radius: 13px;
                            padding: 3px 8px;
                            color: #4385f5;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            margin-right: 8px;
                            margin-bottom: 8px;

                            span {
                                margin-right: 5px;
                            }

                            img {
                                display: block;
                                width: 12px;
                                height: 14px;

                            }
                        }

                        .clearFilter {
                            width: 76px;
                            height: 26px;
                            border: 1px solid #a09c99;
                            border-radius: 13px;
                            line-height: 26px;
                            text-align: center;
                            margin-right: 8px;
                            margin-bottom: 8px;
                            cursor: pointer;
                        }



                        // 热门，行业，栏目，发布时间
                        .selectAll-en {
                            border-radius: 13px;
                            padding: 2px 12px;
                            cursor: pointer;
                            color: white;
                            background: #4385f5;
                            margin-right: 20px;
                        }

                        .selectAll-un {
                            color: #333;
                            border-radius: 13px;
                            padding: 2px 12px;
                            cursor: pointer;
                            margin-right: 20px;
                        }

                        .filtersBox {
                            width: 900px;
                            display: flex;
                            align-items: flex-start;
                            flex-wrap: wrap;

                            // 待选项
                            .filters {
                                color: #333;
                                border-radius: 13px;
                                padding: 2px 12px;
                                cursor: pointer;
                            }

                            //待选项选中
                            .filters-active {
                                color: white;
                                background: #4385f5;
                            }

                            //自定义时间
                            .diyTime {
                                margin-left: 10px;
                                display: flex;
                                align-items: center;

                                .timePicker {
                                    width: 119px;
                                    height: 26px;
                                    overflow: hidden;
                                    display: block;
                                    box-sizing: border-box;
                                    border: 1px solid #e8e8e8;
                                    border-radius: 1px;

                                    input {
                                        height: 26px !important;
                                        line-height: 26px !important;
                                    }
                                }

                                .timeMargin {
                                    width: 16px;
                                    height: 1px;
                                    background-color: #979797;
                                    margin: 0 8px;
                                }

                                .searchFilter {
                                    margin: 0;
                                    margin-left: 10px;
                                }
                            }
                        }

                        // 所在地区
                        //待选项
                        .filters-area {
                            min-width: 96px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .inner {
            .pushProjects {
                position: relative;
                background: #ffffff;
                border-radius: 16px;
                box-sizing: border-box;
                box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
                font-size: 14px;

                .list {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .searchNull {
                        margin: 50px auto 60px auto;
                        width: 272px;
                        height: 260px;
                    }

                    .searching {
                        margin: 50px auto 60px auto;
                        width: 272px;
                        height: 200px;
                    }

                    .row {
                        width: 100%;
                        padding: 30px 22px;
                        overflow: hidden;
                        box-sizing: border-box;
                        border-bottom: 1px solid #f5f5f5;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        transition: all 500ms;

                        &:last-child {
                            border-bottom: none;
                            border-bottom-right-radius: 16px;
                            border-bottom-left-radius: 16px;
                        }

                        .topicInfo {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .topicTit {
                                width: 800px;
                                font-weight: 400;
                                text-align: left;
                                color: #333333;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 16px;
                            }


                            .tips {
                                display: flex;
                                align-items: center;

                                .tip {
                                    // width: 80px;
                                    height: 28px;
                                    box-sizing: border-box;
                                    padding: 0 20px;
                                    border-radius: 14px;
                                    font-weight: 400;
                                    text-align: center;
                                    color: #ffffff;
                                    line-height: 28px;
                                    margin-right: 9px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .tip:last-child {
                                    margin-right: 0;
                                }

                                .areaTip {
                                    background: #2984ff;
                                }

                                .columnTip {
                                    background: #00b9d4;
                                }

                                .industryTip {
                                    background: #ff8b00;
                                    display: none;
                                }
                            }

                        }

                        .topicTime {
                            font-weight: 400;
                            text-align: left;
                            color: #999999;
                            line-height: 22px;
                        }


                    }

                    .row:hover {
                        box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21), 0px 3px 5px 0px rgba(0, 0, 0, 0.15);

                        .topicTit {
                            color: #266ddc;
                        }

                        .topicTime {
                            color: #266ddc;
                        }
                    }

                }

            }

            .pageNintionBox {
                padding: 40px 0;
                display: flex;
                justify-content: center;
            }


        }
    }
</style>
<style lang="scss">
    .timePicker {
        width: 119px;
        height: 26px;
        overflow: hidden;
        display: block;
        box-sizing: border-box;
        border: 1px solid #e8e8e8;
        border-radius: 1px;

        input {
            height: 26px !important;
            line-height: 26px !important;
            font-size: 14px !important;
            padding: 0 !important;
            border: none !important;
            text-align: center;
        }

        .el-input__prefix {
            display: none;
        }

        .el-input__suffix {
            display: none;
        }
    }


    .pageNintionBox {
        display: flex;
        align-items: center;
        justify-content: center;

        .pageNumConfirm {
            width: 40px;
            height: 40px;
            opacity: 1;
            background: #ffffff;
            border: 1px solid #f0f2f5;
            border-radius: 50%;
            text-align: center;
            color: #333333;
            line-height: 40px;
            margin-left: 10px;
        }
    }

    .el-pagination.myPager {
        & {
            .btn-prev {
                width: 40px;
                height: 40px;
                opacity: 1;
                background: #ffffff;
                border: 1px solid #f0f2f5;
                border-radius: 50%;
            }

            .btn-next {
                width: 40px;
                height: 40px;
                opacity: 1;
                background: #ffffff;
                border: 1px solid #f0f2f5;
                border-radius: 50%;
            }

            .el-pager {
                .number {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    opacity: 1;
                    background: #ffffff;
                    border-radius: 50%;
                    color: #4486ee;


                }

                .more {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    background-color: transparent;
                    opacity: 1;
                    border-radius: 50%;
                }
            }

            .pageNumTip {
                display: inline-block;
                height: 40px;
                line-height: 40px;
                margin: 0 0px 0 15px;

                span {
                    display: inline;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 40px;
                }

                .pageNumNow {
                    color: #4486ee;
                }
            }

            .el-pagination__jump {
                height: 40px;
                line-height: 40px;

                .el-input {
                    width: 40px;
                    height: 40px;
                    opacity: 1;


                    .el-input__inner {
                        width: 40px;
                        height: 40px;
                        opacity: 1;
                        background: #ffffff;
                        border: 1px solid #f0f2f5;
                        border-radius: 50%;
                    }
                }
            }

            .btn-quickprev {
                display: none;
            }

            .btn-quicknext {
                display: none;
            }



        }

    }

    .el-pagination.myPager.hideLastPage {
        .el-pager {
            .number {
                width: 40px;
                height: 40px;
                line-height: 40px;
                opacity: 1;
                background: #ffffff;
                border-radius: 50%;
                color: #4486ee;

                &:last-child {
                    display: none;
                }

            }

            .more {
                width: 40px;
                height: 40px;
                line-height: 40px;
                background-color: transparent;
                opacity: 1;
                border-radius: 50%;
            }


        }
    }
</style>